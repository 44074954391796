html,
body {
  font-family: RealBook, Helvetica, Arial, sans-serif;
  margin: 0;
}

/* moneybag text 13px --> 16px, money ---> 30px */

@font-face {
  src: local('RealBold'), url('./fonts/RealBold.otf') format('opentype');
  font-family: 'RealBold';
}
@font-face {
  src: local('RealBoldItalic'),
    url('./fonts/RealBoldItalic.otf') format('opentype');
  font-family: 'RealBoldItalic';
}
@font-face {
  src: local('RealBook'), url('./fonts/RealBook.otf') format('opentype');
  font-family: 'RealBook';
}
@font-face {
  src: local('RealBookItalic'),
    url('./fonts/RealBookItalic.otf') format('opentype');
  font-family: 'RealBookItalic';
}
@font-face {
  src: local('RealLight'), url('./fonts/RealLight.otf') format('opentype');
  font-family: 'RealLight';
}
@font-face {
  src: local('RealSemiBold'), url('./fonts/RealSemiBold.otf') format('opentype');
  font-family: 'RealSemiBold';
}
